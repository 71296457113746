import React, { useEffect, useState, useContext } from 'react';
import { FilterContext } from '../FilterProvider';
import styled from 'styled-components';
import { AccordionGroup } from '../index';
import { cancerTypes } from '../../util/data/cancerTypes';
import { indications } from '../../util/data/indications';
import { NotationBlock } from '../../util/typography';
import ComponentWrapper from '../layout/ComponentWrapper';
import IndicationCTA from './IndicationCTA';
import IndicationDosing from './IndicationDosing';
import { FeaturedCTA } from '../../components';
import { indicationDosingContent, cancerTypeFootnotes } from './dosingContent';

// @TODO: This component has grown in its scope and needs to be refactored to simplify the logic, break things out, and
// perhaps optimize performance.

const MaxWidthComponentWrapper = styled(ComponentWrapper)`
    max-width: 824px;
`;

export const sharedStrings = {
    efficacy: {
        listName: 'Efficacy',
        ctaDataPrefix: 'Efficacy',
        urlPrefix: '/efficacy/'
    },
    safety: {
        listName: 'Safety',
        ctaDataPrefix: 'Selected Adverse Reaction',
        urlPrefix: '/safety/adverse-reactions/' 
    } ,
};

export const createKeynotesText = (keynotes) => {
    let prefixedKeynotes = keynotes.map(keynoteNumber => 'KEYNOTE&#8288;-&#8288;' + keynoteNumber);
    if (prefixedKeynotes.length === 1)
        return prefixedKeynotes[0];
    else {
        const lastKeynote = prefixedKeynotes.pop();
        const andSeparator = prefixedKeynotes.length === 1 ? ' and ' : ', and ';
        return [prefixedKeynotes.join(', '), lastKeynote].join(andSeparator);
    }
};

// For typical CTAs, use the keynotes/slug properties. For special cases, specify CTAs using efficacyCTA and safetyCTA.
export const triageCTAs = {
    1: { keynotes: ['868'], slug: 'advanced-or-recurrent-endometrial-carcinoma' },
    2: { keynotes: ['006'], slug: 'melanoma-monotherapy' },
    3: { keynotes: ['054', '716'], slug: 'melanoma-adjuvant-therapy' },
    4: { keynotes: ['189'], slug: 'nsclc-nonsquamous-first-line-combination-therapy' },
    5: { keynotes: ['407'], slug: 'nsclc-squamous-first-line-combination-therapy' },
    6: { keynotes: ['042'], efficacyKeynotes: ['024', '042'], slug: 'nsclc-first-line-monotherapy' },
    7: { keynotes: ['010'], slug: 'nsclc-second-line-monotherapy' },
    8: { keynotes: ['426'], slug: 'advanced-renal-cell-carcinoma' },
    9: { keynotes: ['048'], slug: 'hnscc-first-line-combination-therapy' },
    10: { keynotes: ['048'], slug: 'hnscc-first-line-monotherapy' },
    // For an internal link that opens in a new tab, use href instead of url and add target: '_blank'
    // Using url, CTA.setPropsFromURL will automatically set links starting with '/' to be an internal Gatsby Link
    //if we add these link exceptions, we need to add the indication numbers in sitemap.js in excludeIndictaions
    11: {
        efficacyCTA: { href: '/prescribing-information/', label: 'View Prescribing Information', target: '_blank' },
        safetyCTA: { href: '/prescribing-information/', label: 'View Prescribing Information', target: '_blank' }
    },
    // 12: {},
    13: { keynotes: ['629'], slug: 'cutaneous-squamous-cell-carcinoma' },
    14: { keynotes: ['017', '913'], slug: 'merkel-cell-carcinoma' },
    15: { keynotes: ['052'], slug: 'urothelial-carcinoma-first-line-monotherapy' },
    16: { keynotes: ['045'], slug: 'urothelial-carcinoma-second-line-monotherapy' },
    17: { keynotes: ['057'], slug: 'nmibc' },
    18: { keynotes: ['859'], slug: 'gastric-adenocarcinoma' },
    19: { keynotes: ['590'], efficacyKeynotes: ['590', '180', '181'], slug: 'esophageal-carcinoma' },
    20: { keynotes: ['204'], efficacyKeynotes: ['204', '087'], slug: 'classical-hodgkin-lymphoma' },
    21: { keynotes: ['170'], slug: 'pmbcl' },
    22: { keynotes: ['158', '164', '051'], slug: 'msi-h-dmmr' },
    23: { keynotes: ['177'], slug: 'msi-h-dmmr-colorectal-cancer' },
    24: { keynotes: ['158'], slug: 'cervical-cancer-second-line-monotherapy' },
    // 25: { keynotes: ['158'], slug: 'tumor-mutational-burden-high' },
    26: { keynotes: ['355'], slug: 'triple-negative-breast-cancer' },
    27: { keynotes: ['522'], slug: 'early-stage-triple-negative-breast-cancer' },
    28: { keynotes: ['826'], slug: 'cervical-cancer-first-line-combination-therapy' },
    29: { keynotes: ['564'], slug: 'adjuvant-renal-cell-carcinoma' },
    30: { keynotes: ['158'], slug: 'advanced-endometrial-carcinoma' },
    31: {
        efficacyCTA: { url: 'http://www.keytrudalenvimahcp.com', label: 'View Efficacy Data' },
        safetyCTA: { url: 'http://www.keytrudalenvimahcp.com', label: 'View Selected Adverse Reaction Data' }
    },
    32: {
        efficacyCTA: { url: 'https://www.keytrudalenvimahcp.com/endometrial-carcinoma/', label: 'View Efficacy Data' },
        safetyCTA: { url: 'https://www.keytrudalenvimahcp.com/endometrial-carcinoma/', label: 'View Selected Adverse Reaction Data' }
    },
    33: { keynotes: ['091'], slug: 'nsclc-adjuvant-therapy' },
    34: { keynotes: ['671'], slug: 'nsclc-neoadjuvant-therapy'},
    35: { keynotes: ['966'], slug: 'advanced-biliary-tract-cancer-combination-therapy' },
    36: { keynotes: ['A39'], slug: 'urothelial-cancer-first-line-combination-therapy' },
    37: { keynotes: ['A18'], slug: 'cervical-cancer-combination-chemoradiotherapy' },
};

const TriageList = props => {
    let isBrowser = typeof window !== 'undefined' && window.location.protocol !== 'data:';
    let onHomepage = isBrowser && window.location.pathname == '/';

    const [hasMounted, setHasMounted] = useState(false);
    const { filteredCancerTypes } = useContext(FilterContext);

    //Ensure this component refreshes from JS and not Gatsby cache https://www.joshwcomeau.com/react/the-perils-of-rehydration/
    useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    //The canonical order of Cancer Types - removing items from this list will 'hide' them from all Triage list displays
    let cancerTypeOrder = [2,3,5,13,14,9,10,15,16,11,12,17,22,8,4,21,7,19];

    //filter the list, if applicable
    if (filteredCancerTypes.length) {
        cancerTypeOrder = cancerTypeOrder.filter((cancerTypeId) => filteredCancerTypes.includes(cancerTypeId))
    } else {
        // If there are no filters applied on the homepage, bypass the performance-hungry creation of cancerTypeList since we won't
        // render anyway. This can be made more elegant as part of a refactor.
        if (onHomepage) {
            return null;
        }
    }

    const cancerTypeList = cancerTypeOrder
        .map((cancerTypeId, index) => {
            const indicationList = cancerTypes[cancerTypeId].indications.map((indicationID, indicationIndex) => {
                //if no indications exist for whatever reason, exit
                if (!indicationID) return;
                
                //build the indication display props
                const indication = indications[indicationID];
                const definitions = indication.definitions

                const indicationName = (props.listType === 'dosing' && indication.dosingName)
                    ? indication.dosingName
                    : indication.name;

                const title = indication.modifier
                    ? indication.modifier + ' ' + indicationName
                    : indicationName;

                const body = indication.statement;

                if (props.listType !== 'dosing') {
                    const ctaData = triageCTAs[indicationID];
                    // There are certain cases where the Efficacy KEYNOTE numbers differ from the Safety ones
                    const keynotes = props.listType === 'efficacy' 
                        ? ctaData.efficacyKeynotes || ctaData.keynotes // For Efficacy, check first for Efficacy-specific KEYNOTE numbers
                        : ctaData.keynotes; 
                    const cta = !ctaData.keynotes
                        ? ctaData[`${props.listType}CTA`]
                        : {
                            url: `${sharedStrings[props.listType].urlPrefix}${ctaData.slug}/`,
                            label: `View ${sharedStrings[props.listType].ctaDataPrefix} Data From ${createKeynotesText(keynotes)}`,
                        };

                    return (
                        <IndicationCTA
                            key={indicationID}
                            title={title}
                            body={body}
                            definitions={definitions}
                            cta={cta}
                        />
                    );
                } else {
                    return (
                        <>
                            {/* Exclude combination linkouts from Dosing Triage */}
                            {(!indication.isCombination) && 
                                <IndicationDosing
                                    title={title}
                                    body={indicationDosingContent[indicationID]}
                                />
                            }
                            {/* The last indication in each cancerType is followed by a FeaturedCTA */}
                            {(indicationIndex === cancerTypes[cancerTypeId].indications.length - 1) &&
                            <MaxWidthComponentWrapper>
                                <FeaturedCTA 
                                    title="Review Dose Modifications for Adverse Reactions"
                                    ctas={[{label: "Dose Modifications", url: "/dosing/dose-modifications"}]}
                                    iconAlt=""
                                />
                                {/* Some cancerTypes include footnotes and definitions */}
                                {(cancerTypeFootnotes[cancerTypeId].footnotes
                                || cancerTypeFootnotes[cancerTypeId].definitions) &&
                                <NotationBlock
                                    footnotes={cancerTypeFootnotes[cancerTypeId].footnotes}
                                    definitions={cancerTypeFootnotes[cancerTypeId].definitions}
                                />}
                            </MaxWidthComponentWrapper>}
                        </>
                    )
                }
            });

            return {
                title: cancerTypes[cancerTypeId].name,
                subtitle: cancerTypes[cancerTypeId].modifier,
                children: indicationList,
            };
        });

    if (props.homepage) {
        return (
            <>
                {filteredCancerTypes.length !== 0 && <AccordionGroup items={cancerTypeList} defaultAllOpen={filteredCancerTypes.length} homepage />}
            </>
        );
    } else {
        return <AccordionGroup includeFilter items={cancerTypeList} defaultAllOpen={filteredCancerTypes.length} />;
    }
};

export default TriageList;
