import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Heading, Text } from '../../util/typography';
import * as CTA from '../../util/CTA';
import * as colors from '../../util/colors';
import { rgba, between } from 'polished';
import { HOMEPAGE_HERO_CONTENT_WRAPPER } from '../../constants/z-index-map';
import { StaticImage } from 'gatsby-plugin-image';
import { createMarkup } from '../../util/functions';
import { useGroup } from '../../util/hooks';

// @TODO: Make color gradient end point colors configurable with knobs
// @TODO: Make background image configurable with knobs

export const IMAGE_DISPLAY_BREAKPOINT = 800;
const IMAGE_SECOND_BREAKPOINT = 520;

const classNamePrefix = 'homepage-hero';

const gradient1 = `linear-gradient(
    71deg, 
    ${colors.keytrudaGreenWeb}, 
    ${colors.keytrudaGreenWeb} 33%, 
    ${rgba(colors.keytrudaGreenWeb, 0.02)} 52%, 
    ${rgba(colors.keytrudaGreenWeb, 0)} 72%
)`

const gradient2 = `radial-gradient(
    circle at top right,
    ${colors.keytrudaGreen}, 
    ${colors.keytrudaGreen} 9%, 
    ${rgba(colors.keytrudaGreen, 0)} 31%
)`

const Container = styled.div`
    width: 100%;
    background: ${colors.keytrudaGreenWebForBanner};
    background-image: ${gradient1}, ${gradient2};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    border-radius: 8px;
    min-height: 324px;
    overflow: hidden;
    
    @media (max-width: ${IMAGE_DISPLAY_BREAKPOINT}px) {
        flex: 1 1 100% !important;
    }
`;

const ContainerInner = styled.div`
    --contentWidth: 63%;
    --imageWidth: 37%;
    --imageMaxWidth: 330px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    @media (max-width: ${IMAGE_SECOND_BREAKPOINT}px) {
        flex-wrap: wrap;
    }
`;

const ContentColumn = styled.div`
    padding: 30px 5px 30px clamp(20px, ${between('20px', '40px', `${IMAGE_DISPLAY_BREAKPOINT}px`, '1032px')}, 40px);

    flex: 3 1 300px;
    z-index: ${HOMEPAGE_HERO_CONTENT_WRAPPER};

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: stretch;

    span {
        display: block;
    }

    @media (max-width: ${IMAGE_DISPLAY_BREAKPOINT}px) {
        padding: 20px 5px 20px clamp(20px, ${between('20px', '40px', `${IMAGE_SECOND_BREAKPOINT}px`, `${IMAGE_DISPLAY_BREAKPOINT}px`)} ,40px);
    }

    @media (max-width: ${IMAGE_SECOND_BREAKPOINT}px) {
        padding: 20px;
    }
`;

const ImageColumn = styled.div`
    flex: 1 1 200px;
    display: flex;
    justify-content: center;
    max-width: none;
    align-self: flex-end;

    .gatsby-image-wrapper {
        display: block;
        width: 100%;
        max-width: 236px;
        max-height: 290px;
        margin: 0 auto;
    }

    margin-right: clamp(-14px, ${between('-14px', '0px', '840px', '1040px')}, 0px);

    @media (max-width: ${IMAGE_SECOND_BREAKPOINT}px) {
        padding-top: 0;
        margin-right: 0;
        margin-bottom: -64px;
        
        .gatsby-image-wrapper {
            max-width: 200px;
        }
    }
`;

const Headline = styled(Heading.H3).attrs({
    className: `${classNamePrefix}-headline-text`
})`
    color: white;
`;

const BodyText = styled(Text.Body).attrs({
    className: `${classNamePrefix}-body-text`
})`
    color: white;
    margin-top: 7px;
`;

const ButtonWrapper = styled.div`
    margin-top: clamp(8px, ${between('8px', '16px', '320px', '1032px')}, 16px);

    @media (min-width: ${IMAGE_SECOND_BREAKPOINT + 1}px) {
        display: flex;
        align-items: center;
        
        > * {
            margin-right: 1rem;
            margin-bottom: 0;
        }
        > *:last-child {
            margin-right: 0;
        }
    }
`;


const PrimaryButton = styled(CTA.PrimaryButton).attrs({
    className: `${classNamePrefix}-button`
})`
    margin-top: 10px;
    
    span {
        padding-left: 27px;
        padding-right: 27px;
    }
`;

const TertiaryButton = styled(CTA.TertiaryButton).attrs({
    className: `${classNamePrefix}-button`
})`

`;

const defaultContent = {
    headlineText: 'A Key to More Possibilities for Treating Your Patients:',
    bodyText: '<p>KEYTRUDA Is Approved to Treat 17 Types of Cancer, Including Certain Early-Stage and Advanced Cancers.</p><p>One advanced indication is for the treatment of patients with unresectable or metastatic melanoma.</p> ',
    button: { 
        type: 'primary',
        label: 'View Select Indications', 
        url: '/approved-indications/',
        theme: 'light'
    },
};

const customContentBase = {
    bodyText: '',
    button: {
        type: 'tertiary',
        label: 'View additional indications', 
        url: '/efficacy/',
        theme: 'light'
    }
};

/**
 * This is the list of group strings that correspond to approved Custom Landing pages. As such, the keys seen below
 * are the ONLY strings that the useGroup() hook will use while on the homepage. (For any other page, useGroup() will check
 * its received string against the keys from cancerTypeKeyIdMap from cancerTypes.js.)
 */
export const cancerTypeCustomContent = {
    'cer' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With Advanced Cervical Cancer',
        ...customContentBase,
    },
    'nsc' : {
        headlineText: 'A Key to Treating Your Appropriate Patients with Advanced or Earlier-Stage Non–Small Cell Lung Cancer',
        ...customContentBase,
    },
    'bre' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With High-Risk Early-Stage Triple-Negative Breast Cancer and Advanced Triple-Negative Breast Cancer',
        ...customContentBase,
    },
    'bla' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With High-Risk Non-muscle Invasive Bladder Cancer',
        ...customContentBase,
    },
    'mel' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With Melanoma in the Adjuvant and Advanced Settings',
        ...customContentBase,
    },
    'hns' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With Advanced Head and Neck Squamous Cell Carcinoma',
        ...customContentBase,
    },
    'end' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With Advanced Endometrial Carcinoma',
        ...customContentBase,
    },
    'ren' : {
        headlineText: 'A Key to Treating Your Appropriate Patients With Renal Cell Carcinoma in the Adjuvant and Advanced Settings',
        ...customContentBase,
    }
};

const CustomHomepageHero = () => {
    const group = useGroup();
    const [heroContent, setHeroContent] = useState(() => {
        if (group) {
            return cancerTypeCustomContent[group];
        }
        else return defaultContent;
    });
    useEffect(() => {
        /**
         * An additional check to make sure the group string exists within cancerTypeCustomContent (although
         * because this component only exists on the homepage, useGroup() should have already validated against this)
         */
        if (group && cancerTypeCustomContent[group]) {
            setHeroContent(cancerTypeCustomContent[group]);
        }
    }, [group])

    /**
     * Render default content on the server, and force React to fully re-render on the client side by updating its key. For SEO and
     * performance, we want to SSR the default — the downside is that custom pages will flash default before loading custom content.
     * https://github.com/gatsbyjs/gatsby/discussions/17914 — see "Two pass rendering" (instead of https://www.joshwcomeau.com/react/the-perils-of-rehydration/)
     */
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
        setHasMounted(true);
    }, []);

    const Button = heroContent.button.type == 'primary' ? PrimaryButton : TertiaryButton;

    return (
        <Container>
            <ContainerInner>
                <ContentColumn key={`hero-${hasMounted}`}>
                    <Headline as='h1' dangerouslySetInnerHTML={createMarkup(heroContent.headlineText)}/>
                    {(heroContent.bodyText !== '') ? <BodyText dangerouslySetInnerHTML={createMarkup(heroContent.bodyText)} /> : <></>}
                    <ButtonWrapper>
                        <Button
                            label={heroContent.button.label}
                            url={heroContent.button.url}
                            theme={heroContent.button.theme}
                        />
                    </ButtonWrapper>

                </ContentColumn>
                <ImageColumn>
                    <StaticImage placeholder="blurred" src="../../assets/hand-3@2x.png" alt="KEYTRUDA® (pembrolizumab): Website for Health Care Professionals" objectFit="contain" objectPosition="bottom" />
                </ImageColumn>
            </ContainerInner>
        </Container>
    )
};

export default CustomHomepageHero;
