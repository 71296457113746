import React from 'react';
import styled from 'styled-components';
import {
    TieredCTAGrid,
    TriageList
} from '../index';
import Card from '../TieredCTAGrid/Card';
import * as colors from '../../util/colors';
import CustomHomepageHero from './CustomHomepageHero';
import { IMAGE_DISPLAY_BREAKPOINT } from './CustomHomepageHero';

const Section = styled.section`
    background-color: ${colors.mist};
    padding: 30px 0 40px;
`;

const Container = styled.div`
    margin: 0 auto;
    max-width: ${992 + 40}px;
    padding: 0 20px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: calc(67% - 10px) calc(33% - 10px);
    grid-template-areas:
        "hero card"
        "triage triage";
    column-gap: 20px;
    margin-bottom: -20px;
    
    @media (max-width: ${IMAGE_DISPLAY_BREAKPOINT - 1}px) {
        grid-template-areas:
        "hero hero"
        "triage triage"
        "card card";
    }
`;

const HeroWrapper = styled.div`
    grid-area: hero;
    margin-bottom: 20px;
`;

const CardWrapper = styled.div`
    grid-area: card;
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;
`;

const TriageWrapper = styled.div`
    grid-area: triage;
`;

// @todo implement theme
const CustomHomepageGrid = ({ tieredCtaRows, heroCta  }) => {
    
    return (
        <Section>
            <Container>
                <GridContainer>
                    <HeroWrapper>
                        <CustomHomepageHero />
                    </HeroWrapper>
                    <CardWrapper>
                        <Card 
                            rowTier="2"
                            verticalCenter
                            rowKey={-1}
                            colKey={-1}
                            {...heroCta}
                        />
                    </CardWrapper>
                    <TriageWrapper>
                        <TriageList listType="efficacy" homepage />
                    </TriageWrapper>
                </GridContainer>
                <TieredCTAGrid rows={tieredCtaRows} homepage />
            </Container>
        </Section>
    );
};

export default CustomHomepageGrid;