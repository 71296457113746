export const jobCodes = {
    0: {
        // MT
        id: 0,
        jobCode: 'US-KEY-08003 05/24',
    },
    // 1: {
    //     // HCC
    //     id: 1,
    //     jobCode: 'US-PDO-01572 01/22',
    // },
    2: {
        // MEL
        id: 2,
        jobCode: 'US-OOC-02405 11/23',
    },
    // 3: {
    //     // MEL - Adjuvant
    //     id: 3,
    //     jobCode: 'US-OOC-01141  05/21',
    // },
    4: {
        // ALL LUNG / NSCLC
        id: 4,
        jobCode: 'US-LAM-03257 11/23',
    },
    5: {

    },
    6: {

    },
    7: {

    },
    8: {
        // RCC (adjuvant)
        id: 8,
        jobCode: 'US-JRC-00454 12/22',
    },
    9: {
        // HNSCC
        id: 9,
        jobCode: 'US-OHN-01939 05/24',
    },
    10: {
        // 1L Mono HNSCC
        id: 10,
        jobCode: '',
    },
    11: {
        // 2L Mono HNSCC
        id: 11,
        jobCode: '',
    },
    // 12 is missing since the ids were duplicated from indications.js
    13: {
        // cSCC
        id: 13,
        jobCode: 'US-OHC-00315 09/23',
    },
    14: {
        // MCC
        id: 14,
        jobCode: 'US-PDO-02475 04/24',
    },
    15: {
        // UC
        id: 15,
        jobCode: 'US-OBD-01547 04/24',
    },
    16: {
        // id: 16,
        // jobCode: 'US-OBD-00748  05/21',
    },
    17: {
        // NMIBC
        id: 17,
        jobCode: 'US-NMI-00702 03/24',
    },
    18: {
        // GASTRIC
        id: 18,
        jobCode: 'US-OGA-00599 12/23',
    },
    19: {
        // ESO
        id: 19,
        jobCode: 'US-ESO-00116 04/24',
    },
    20: {
        // CHL
        id: 20,
        jobCode: 'US-PDO-02179 05/23',
    },
    21: {
        // PMBCL
        id: 21,
        jobCode: 'US-PDO-02178 05/23',
    },
    22: {
        // MSI-H
        id: 22,
        jobCode: 'US-OVA-00928 04/23',
    },
    23: {
        // CRC
        id: 23,
        jobCode: 'US-OVC-00640 05/23',
    },
    24: {
        // CERVICAL
        id: 24,
        jobCode: 'US-CER-00128 02/24',
    },
    25: {
        // TMB-H
        id: 25,
        jobCode: 'US-TMB-00085 05/23',
    },
    26: {
        // TNBC
        id: 26,
        jobCode: 'US-OBR-01660 01/24',
    },
    // not sure if these will continue to have their own job codes or if it will be a part of MT submission in the future
    27: {
        // ALL Biomarker pages
        id: 27,
        jobCode: 'US-KEY-08002 03/24',
    },
    28: {
        // MSI-H Biomarker
        // id: 28,
        // jobCode: 'US-KEY-06845 06/23',
    },
    29: {
        // TMB-H Biomarker
        // id: 29,
        // jobCode: 'US-TMB-00081 07/22',
    },
    30: {
        // MT DOSING
        id: 30,
        jobCode: 'US-KEY-08004 06/24',
    },
    31: {
        // RESOURCES (RESOURCE CENTER + MOA + KEY+YOU)
        id: 31,
        jobCode: 'US-KEY-08378 05/24',
    },
    32: {
        // ENDO
        id: 32,
        jobCode: 'US-END-00498 06/24',
    },
    33: {
        // RCC (advanced)
        id: 33,
        jobCode: 'US-RCC-00992 03/23',
    },
    34: {
        // Dose Modifications, Admin & Prep
        id: 34,
        jobCode: 'US-KEY-06175 01/23',
    },
    35: {
        // AA Dosing
        id: 35,
        jobCode: 'US-KEY-06174 01/23',
    },
    36: {
        // Biliary
        id: 36,
        jobCode: 'US-KEB-00029 11/23',
    },
};
